

h1 {
  font-size: 2vw;
}

h3 {
  font-size: 2vw;
}

.App {
  height: 100%;
  text-align: center;
}

h2 {
  font-size: 3vh
}

.header {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  background-color: #E5173F;
  color: white;
  margin-top: -5px;
  padding: 1vh;
  font-size: 4vh;
}

.title {
  grid-column: 2;
  background-color: #E5173F;
  color: white;
  margin-top: -5px;
  margin-bottom: -5px;
  padding: 1vh;
  font-size: 4vw
}

.menuContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.menu {
  grid-column: 1;
  position: relative;
}

.menu:hover {
  filter: brightness(90%)
}

.menu:active {
  filter: brightness(60%)
}

.today {
  display: grid;
  grid-template-rows: 15% 85%;
}

.upperSection {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 2vw;
  padding: 1vw;
  padding-bottom: 5vw;
  background-color: white;
  border-radius: 15px;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
}

.feelsLike {
  display: grid;
  font-size: 3vw;
  margin: 0;
  padding: 0;
  text-align: center;
  grid-row: 1;
  grid-column: 1;
}

.feelsLikeIcon {
  text-align: center;
  width: 5vw;
  margin: none;
  grid-column: 2;
  color: yellow;
}

.marginContainer {
  display: grid;
  font-size: 5vw;
  margin: 0;
  padding: 0;
  text-align: center;
  grid-row: 1;
  grid-column: 2;
}

.popout {
  display: none;
  position: absolute;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
  padding-top: 10px;
  width: 30%;
  color: black;
  background-color: #E5173F;
  z-index: 2;
}

.notes {
  grid-row: 3;
  grid-column: 1 / 4;
}

.future {
  text-align: center;
  margin-left: 10px;
  width: 90%;
  margin-top: 4px;
  border-radius: 15px;
  border-top: 0px;
  background-color: white;
  height: 7%;
  font-size: 1.5vw;
  font-weight: 600;
  padding-left: 8%;
  padding-bottom: 5%;
}

.future:hover {
  background-color: rgb(235, 235, 235);
}

.iconSection {
  margin-top: 15px;

  text-align: center;
  grid-row: 2;
  display: grid;
  grid-template-columns: 10% 1fr 5% 1fr 10%;
}

.infoSection {
  border-radius: 15px;
  grid-column: 2;
  background-color: rgb(255, 255, 255, 0.4);
}

.notesSection {
  border-radius: 15px;
  grid-column: 4;
  background-color: rgb(255, 255, 255, 0.4);
}

.peabody {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  grid-column: 1;
  width: 12vw;
}

.rotation {
  margin-left: auto;
  margin-right: auto;
  display: block;
  grid-column: 2;
  width: 12vw;
}

.rotationDescription {
  align-self: center;
  grid-column: 2;
}

.rotationDescription,
.laneDescription,
.peabodyDescription {
  align-self: center;
  grid-row: 1;
  font-size: 2vw;
  margin-top: 1vw;
}

.noGo {
  margin-top: 5em;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  font-size: 1.5em;
}

.umbrella {
  background-color: lightgrey;
  border-radius: 10px;
}

.lightning {
  width: 92%;
}

.lightalert {
  font-size: 8em;
}

@media only screen and (max-width: 600px) {
  html {
    background-color: lightblue;
    height: 100%;
  }

  .upperSection {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2vw;
    padding: 1vw;
    padding-bottom: 5vw;
    background-color: white;
    border-radius: 15px;
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr;
  }

  .today {
    display: grid;
    grid-template-rows: 10% 90%;
  }

  .title {
    grid-column: 2;
    background-color: #E5173F;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 2vh;
    font-size: 4vw
  }

  .iconSection {
    margin-top: 15px;
    text-align: center;
    grid-row: 2 / 5;
    display: grid;
    grid-template-rows: 60% 10% 30%;
  }

  .infoSection {
    border-radius: 15px;
    grid-row: 1;
    grid-column: 2 / 5;
    background-color: rgb(255, 255, 255, 0.4);
    height: 50vh;
  }

  .notesSection {
    margin-top: 50px;
    height: 15vh;
    border-radius: 15px;
    grid-row: 3;
    grid-column: 2 / 5;
    background-color: rgb(255, 255, 255, 0.4);
  }

  .peabody {
    width: 20vh;
  }

  .rotation {
    width: 20vh;
  }
}

@media only screen and (min-width: 601px) {
  html {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    url("https://user-images.githubusercontent.com/62782385/242339522-cb1a78e6-958c-4b19-94d5-b5f33ce60e6e.png");
    background-size: cover;
  }
}
